import { extend, localize } from 'vee-validate'
import {
	alpha as rule_alpha,
	alpha_dash as rule_alpha_dash,
	alpha_num as rule_alpha_num,
	alpha_spaces as rule_alpha_spaces,
	between as rule_between,
	confirmed as rule_confirmed,
	digits as rule_digits,
	dimensions as rule_dimensions,
	email as rule_email,
	excluded as rule_excluded,
	ext as rule_ext,
	image as rule_image,
	oneOf as rule_oneOf,
	integer as rule_integer,
	length as rule_length,
	max as rule_max,
	max_value as rule_max_value,
	mimes as rule_mimes,
	min as rule_min,
	min_value as rule_min_value,
	numeric as rule_numeric,
	regex as rule_regex,
	required as rule_required,
	required_if as rule_required_if,
	size as rule_size,
	double as rule_double,
} from 'vee-validate/dist/rules'
import ar from 'vee-validate/dist/locale/ar.json'
import en from 'vee-validate/dist/locale/en.json'
//import es from 'vee-validate/dist/locale/es.json'

// eslint-disable-next-line object-curly-newline
import { validatorPositive, validatorUrlValidator, validatorPassword, validatorCreditCard } from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const alpha = extend('alpha', rule_alpha)
export const alphaDash = extend('alpha-dash', rule_alpha_dash)
export const alphaNum = extend('alpha-num', rule_alpha_num)
export const alpha_spaces = extend('alpha_spaces', rule_alpha_spaces)
export const between = extend('between', rule_between)
export const confirmed = extend('confirmed', rule_confirmed)
export const digits = extend('digits', rule_digits)
export const dimensions = extend('dimensions', rule_dimensions)
export const email = extend('email', rule_email)
export const excluded = extend('excluded', rule_excluded)
export const ext = extend('ext', rule_ext)
export const image = extend('image', rule_image)
export const oneOf = extend('oneOf', rule_oneOf)
export const integer = extend('integer', rule_integer)
export const length = extend('length', rule_length)
export const max = extend('max', rule_max)
export const max_value = extend('max_value', rule_max_value)
export const mimes = extend('mimes', rule_mimes)
export const min = extend('min', rule_min)
export const min_value = extend('min_value', rule_min_value)
export const numeric = extend('numeric', rule_numeric)
export const regex = extend('regex', rule_regex)
export const required = extend('required', rule_required)
export const required_if = extend('required_if', rule_required_if)
export const size = extend('size', rule_size)
export const double = extend('double', rule_double)

export const positive = extend('positive', {
	validate: validatorPositive,
	message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
	validate: validatorCreditCard,
	message: 'It is not valid credit card!',
})

export const password = extend('password', {
	validate: validatorPassword,
	message: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
})

export const url = extend('url', {
	validate: validatorUrlValidator,
	message: 'URL is invalid',
})
/* 
export const romano = extend('romano', {
	validate: validatorRomanoValidator,
	message: 'Debe ser número romano',
})  
 */


// Install English and Arabic localizations.
localize({
	// es: {
	// 	messages: es.messages,
	// 	names: {
	// 		email: 'Email',
	// 		password: 'Password',
	// 	},
	// 	fields: {
	// 		password: {
	// 			min: '{_field_} is too short, you want to get hacked?',
	// 		},
	// 	},
	// },
	en: {
		messages: en.messages,
		names: {
			email: 'Email',
			password: 'Password',
		},
		fields: {
			password: {
				min: '{_field_} is too short, you want to get hacked?',
			},
		},
	},
	ar: {
		messages: ar.messages,
		names: {
			email: 'البريد الإلكتروني',
			password: 'كلمة السر',
		},
		fields: {
			password: {
				min: 'كلمة السر قصيرة جداً سيتم اختراقك',
			},
		},
	},
})

//localize('es');

// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
